*{
    margin: 0%;
    padding: 0;
}
.body{
    margin: 2rem auto;
    /* transition: all .4s; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}
button:focus {outline:0;}
.alert-danger{
    color: #4d4d4d;
    background-color: #cacaca;
    border-color: #cecece;
}
.login_text{
    padding-top: 71px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 0%;
    text-align: center;
}
.campos{
    margin-top: 90px;
}
.input{
    margin-bottom: 30px;
    width: 500px;
    height: 40px;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    border: none;
    border-bottom: 1px solid #7A7A7A;
    background: transparent; 
    outline: none;
}
.uwu{
    display: flex;
    flex-direction: column;

}
.inputs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}
.boton{
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}
.aceptar{
    margin-top: 20px;
    cursor: pointer;
    width: 239px;
    height: 55px;
    border-radius: 50px;
    background-color: #1E1E1E;
    border: none;
    font-family: Montserrat !important;
    font-style: normal;
    color: #D3D3D3;
    font-weight: normal;
    font-size: 24px;
    line-height: 0%;
    transition: all .4s;
}
.dark-bg{
    background:  #272727;
}

.light-bg{
    background: #F8F8F8;
}
.aceptar:hover{
    transform: scale(1.1);
}
.registrar{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    margin-top: 30px;
}
.textoo{
    display: grid;
    text-align: center;
    grid-template-columns: .1fr .8fr .1fr ;
}

@media (max-width:600px){
    .input{
        width: 350px;
    }
    .aceptar{
        width: 200px;
        height: 60px;
        border-radius: 50px;
    }
    .login_text{
        font-size: 60px;
    }
    .registrar{
        font-size: 15px;
    }
}