@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
body{
    overflow-x: hidden;
}
.tabla-admin{
    font-family: 'Nunito', sans-serif;
}
.thead tr th{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #282828;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
td p a{
    color: #282828 !important;
    text-decoration: none;
}
.table thead th{
    border: none;
}
.profile-imgs{
    width: 71px;
    height: 57px;
    object-fit: cover;
    border-radius: 3px;
}
.container{
    max-width: 1400px;
    padding: 0 !important;
}
.botonera-admin{
    margin-top: 50px;
    margin-left: 30px;
    background-color: #E5E5E5;
    height: 60px;
    width: 264px;
    display :flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    border-radius: 30px;
}
#b1,#b2,#b3,#b4 {
    cursor: pointer;
}
.botonera{
    margin-bottom: 15px !important;
}

.boton-elim-border{
    border-left:  1px solid #D3D3D3;;
}
.contenedor-de-tabla{
    background: #FFFEFE;
    border-radius: 0px 0px 22px 22px;
}
.btn-elim{
    transition: all .1s;
}
.btn-elim:hover{
    filter: brightness(80%);
}
.contenerdor-centro{
    width: 100%;
    display: flex;
    /* margin-top: 150px; */
    align-items: center;
    justify-content: space-around;
}
.thead-style{
    background-color: #FFFEFE !important;
}

.entradas-panel{
    overflow:auto;
    width: 60%;
    background-color: #d3d3d3;
    height: 400px;
    border-radius:10px;
}
.entradas-recientes{
    text-align: center;
}
.entrada:hover{
    transform: scale(1.02);
    cursor: pointer
}
.entrada{
    transition: all .3s;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    width: 70%;
    height: 100px;
    background-color: #FFFEFE ;
    margin: 9px auto;
    justify-content: center;
    border-radius: 10px;
}
.entrada p{
    margin: 0 !important;
}
.contenido-entrada{
    width: 500px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}
.contenido-entrada p{
    margin-left: 30px !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
}
.foto-contenido-entrada{
    cursor: pointer;
    width: 120px;
    height: 80px;
    object-fit: cover;
    border-radius: 15px;
}

.grafico-stats{
margin: auto auto;
    width: 1000px;
}
.contenedor-stats {
width: 100%;
}
.body-todo{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.parte-pagination{
    margin-top: 20px;
}
.b1{
    height: 100%;
}
.a1{
    height: 100%;
}
.loader-entradas{
    
}
.flecha-pagination{
    border-radius: 5px;
    background-color: #d3d3d3;
    color: #333;
    font-size: 20px;
    text-align: center;
    width: 50px;
    height: 30px;
    border: none;
    margin: auto 10px
}
.botones-paginas-tabla{
background: transparent;
border: none;
}
.boton-tabla{
    width: 230px;
height: 62px;
    display: flex !important;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    background-color:#E6E6E6 ;
    border-radius: 17px;
    transition: all .3s;
}
.boton-tabla:hover{
    background-color:#c0c0c0 ;
    
}
.boton-tabla p{
    margin: 0 !important;
    padding: 0 !important;

        
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #282828;
    margin-left:10px !important ;
}