@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
*{
    scroll-behavior: smooth;
}
.total{
    display: flex;
    flex-direction: column;
}
.card{
    margin-top: 50px !important;
}
.card-body{
    height: auto;

    padding: 50px;
}
.pelele{
    text-align: center;
    justify-content: space-around;
    align-items: center;
    display: grid;
    grid-template-rows: .1fr .8fr .1fr;
}
.card-body p{
    margin-top:1rem !important;
}
.registro-txt{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-top: 50px;
    line-height: 0%;
    color: #282828;
}
.ultima-entrada-txt{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 50px;
    line-height: 0%;
    color: #615B5B;
}
.whole-body{
    /* margin-top: 140px; */
    display: grid;
    grid-template-columns: .2fr .8fr;
}
.container1{
    width: 883.08px;
/* height: 426.94px; */
}
.el-grafico-img{

    /* width: 100% !important; */
    width: 500px !important;
    height: 199.01px;
}
.c1{
    display: grid;
    grid-template-rows: .2fr .7fr .1fr;
}
/*perfil datos de la imagen*/
.profile-picture{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile-text{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 0%;color: #676767;
}
.profile-picture-img{
   width: 100px;;
  height: 100px;
  object-fit: cover;
    border-radius: 117px;
}

.change-profile-picture{
    background: #282828;
    border-radius: 50px;
    width: 84px;
    height: 24.56px;
    margin-top: 30px;
    font-family: 'Nunito', sans-serif;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 0%;
    color: #D3D3D3;
}
.qr-code-text{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 0%;
    color: #282828;

}
.qr-code-imag{
    width: 90px;
    height: 90px;
}
.profile-name{
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 25px;
    font-weight: 600;
}

/*datos del perfil*/
.datos-del-perfil{
    margin-left: 100px;
}

.datos-del-perfil-text{
    margin-bottom: 40px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 0%;
    color: #282828;
}
.fields{
    
    width: 400px;
}
.full-name-field{
    display: flex;
    margin-bottom: 18px;
}
.email-field{
    display: flex;
    margin-bottom: 18px;
}
.dni-field{
    display: flex;
    margin-bottom: 18px;
}
.companyid-field{
    
    display: flex;
}
.description{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 0%;
    color: #272727;
}
.hidden{
    display: none;
}
.value-field{
    margin-left: 10px;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    width: 200px;
    color: #272727;
    height: 12px;
    border-bottom: 1px solid #7A7A7A;
}
@media (max-width:1400px){
.total{
    display: flex;
    flex-direction: column;
}
    /* .whole-body{
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    } */
    .profile-picture{
        margin-left: 0;

        margin-bottom: 40px;
    }
    .second-row{
        display: flex;
        /* flex-direction: column; */
        margin-top: 50px;
    }
    .second-row img{
        width: 300px;
        height: auto;
    }
    .qr-zone{
        border: none;
        margin-bottom: 60px;
    }
    .qr-img{
        width: 200px !important;
    }
    .qr-text,.chart-text{
        font-size: 30px;
    }
}