/* FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;1,300;1,400&display=swap');

.navbar{
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: .3fr .7fr ;
    width: 100%;
    background-color:rgb(211, 211, 211);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/*LOGO*/
.logo_section{
    display: flex;
    text-align: center;
    align-items: center;
}

.logo_img{
    cursor: pointer;
    margin-left: 50px;
    height: 65px;
    width: 65px;
}
.logo_name{
    cursor: pointer;
    color: #D3D3D3 !important;
    margin-left: 20px;
    font-size: 38px;
    text-decoration: none !important;
font-family: 'Nunito', sans-serif;
}
/*BUTTONS*/
.nav-links{
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    /* justify-content: space-around; */

}

.culo{
    position:absolute;                  
    right:0; 
}
.nav-link{
font-family: 'Nunito', sans-serif;
    text-decoration: none !important;
    list-style: none;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    color: #D3D3D3;
    height: 100%;
}
div .nav-links{
    align-items: center;
    text-align: center;
}
.register-link{
    margin-right: 31px !important; 
}
.nav-rect{
    margin: auto  10px !important;
    background-color: #1E1E1E;
    width: 141px;
    /* height: 520px; */
    text-align: center;
    border-radius: 50px;
    transition: all .4s;
}
.icon_theme{
    transition: all .4s;

}
.nav-rect:hover {
    background-color: #d8d8d8;
}

.nav-rect:hover  .nav-link{
    color:#333;
}
.nav-link li{
    margin-top: 2px;
}
.theme_toggle{
    cursor: pointer;
    width: 80px;
    height: 34px;
    background-color: #F8F8F8;
    margin-right: 30px;
    user-select: none;
    transition: all .4s;
    border-radius: 48px;
}
.back_rec{
    background: #282828;
    width: 28px;
    height: 28px;
    border-radius: 50px;
    margin-top: 3px;
    transition: all .4s;user-select: none;
    margin-left: 4px;
}
.icon_theme {
    width: 31px;
    height: 31px;
    margin-top: 1px;
    margin-left: 3px;
}
.icon_theme img{
    position: relative;
    text-align: center;
    transition: all .4s;
    margin-top: 1px;

} 
.display-username{
    margin: 0 !important;

    margin-right: 30px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    color: #282828;

}
.sidenav-owo{
    display: none !important;
}
@media (max-width:1000px){
    .sidenav-owo{
        display: unset !important;
    }
    .botones-login-reg{
        display: none;
    }
}
@media (max-width:550px){
    .display-username{
    display: none;
    }
    .theme_toggle{
        display:none
    }
    .inputs{
        display: flex;
    }
    .input{
        width: 100px;
    }
    .logo_name{
        font-size: 24px;
    }
    .logo_img{
        margin: 0;
    }
    .navbar{
        margin: 0;
        padding: 0;
        text-align: center;
        justify-content: space-around;
        display: flex;
    }
    .body{
        display: flex;
    }
}
