@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
*{
    scroll-behavior: smooth;
}.loginn{
    margin: 20vh auto 0 auto;
}
.none{
    display: none;
}
.swal-overlay {
    background-color: #c8c8c898;
  }
  .swal-footer {
    background-color: rgb(245, 248, 250);
    margin-top: 32px;
    border-top: 1px solid #E9EEF1;
    overflow: hidden;
  }
  .swal-button--catch ,.swal-button--confirm {
    background-color: #0069d9;
    color: rgb(240, 240, 240);
  }
#team{
    text-align: center;
}
.aver{
    border-radius: 50%;
}
.arriba{
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.span-logo{
    margin: 1rem auto 1rem auto;
}
.contenedor-servicios{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
    .fotoo{
    width: 40%;
    height: 40%;    
    border-radius: 50% !important;

    object-fit: cover;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .btn-social{
      margin: .5rem auto 2rem auto;
  }
.las-fotos{
    width: 100%;
    text-align: center;
    margin: auto auto;
}
.las-fotos-adentro{
    display: inline-block;
}
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
@media (max-width:400px){
    #uwu{
        text-align: center;
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .input-login{
        justify-content: center;
        width: 200px;
    }
    
    .row{
        margin: auto auto;
        text-align: center;
        align-items: center;
    }
    .col-6{
        max-width: 100%;
        margin: .7rem auto;
    }
    .loginn{
        margin: 10vh auto 0 auto;
    }
    
}
/* FONTS*/

.navbar{
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: .3fr .7fr ;
    width: 100%;
    background-color:rgb(211, 211, 211);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/*LOGO*/
.logo_section{
    display: flex;
    text-align: center;
    align-items: center;
}

.logo_img{
    cursor: pointer;
    margin-left: 50px;
    height: 65px;
    width: 65px;
}
.logo_name{
    cursor: pointer;
    color: #D3D3D3 !important;
    margin-left: 20px;
    font-size: 38px;
    text-decoration: none !important;
font-family: 'Nunito', sans-serif;
}
/*BUTTONS*/
.nav-links{
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    /* justify-content: space-around; */

}

.culo{
    position:absolute;                  
    right:0; 
}
.nav-link{
font-family: 'Nunito', sans-serif;
    text-decoration: none !important;
    list-style: none;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    color: #D3D3D3;
    height: 100%;
}
div .nav-links{
    align-items: center;
    text-align: center;
}
.register-link{
    margin-right: 31px !important; 
}
.nav-rect{
    margin: auto  10px !important;
    background-color: #1E1E1E;
    width: 141px;
    /* height: 520px; */
    text-align: center;
    border-radius: 50px;
    transition: all .4s;
}
.icon_theme{
    transition: all .4s;

}
.nav-rect:hover {
    background-color: #d8d8d8;
}

.nav-rect:hover  .nav-link{
    color:#333;
}
.nav-link li{
    margin-top: 2px;
}
.theme_toggle{
    cursor: pointer;
    width: 80px;
    height: 34px;
    background-color: #F8F8F8;
    margin-right: 30px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: all .4s;
    border-radius: 48px;
}
.back_rec{
    background: #282828;
    width: 28px;
    height: 28px;
    border-radius: 50px;
    margin-top: 3px;
    transition: all .4s;-webkit-user-select: none;-ms-user-select: none;user-select: none;
    margin-left: 4px;
}
.icon_theme {
    width: 31px;
    height: 31px;
    margin-top: 1px;
    margin-left: 3px;
}
.icon_theme img{
    position: relative;
    text-align: center;
    transition: all .4s;
    margin-top: 1px;

} 
.display-username{
    margin: 0 !important;

    margin-right: 30px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    color: #282828;

}
.sidenav-owo{
    display: none !important;
}
@media (max-width:1000px){
    .sidenav-owo{
        display: unset !important;
    }
    .botones-login-reg{
        display: none;
    }
}
@media (max-width:550px){
    .display-username{
    display: none;
    }
    .theme_toggle{
        display:none
    }
    .inputs{
        display: flex;
    }
    .input{
        width: 100px;
    }
    .logo_name{
        font-size: 24px;
    }
    .logo_img{
        margin: 0;
    }
    .navbar{
        margin: 0;
        padding: 0;
        text-align: center;
        justify-content: space-around;
        display: flex;
    }
    .body{
        display: flex;
    }
}


  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  :root {
    --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms; 
  }
  
  .ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .a {
    color: #dadce1;
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .navbar1 {
    height: 60px;
    height: var(--nav-size);
    background-color: #242526;
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: 1px solid #474a4d;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item {
    width: calc(60px * 0.8);
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Icon Button */
  .icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #8b8b8b !important;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
  }
  .i-b-2 {
    background-color: #636363 !important;
  }
  
  .icon-button:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
  
  .icon-button svg { 
    fill: #dadce1; 
    fill: var(--text-color);
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    z-index: 99999;
    top: 65px;
    width: 300px;
    transform: translateX(-45%);
    background-color: #242526;
    background-color: var(--bg);
    border: 1px solid #474a4d;
    border: var(--border);
    border-radius: 8px;
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height 500ms ease;
    transition: height var(--speed) ease;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border-radius: var(--border-radius);
    transition: background 500ms;
    transition: background var(--speed);
    padding: 0.5rem;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    -webkit-filter: none;
            filter: none;
  }
  
  .menu-item:hover {
    background-color: #7a7a7a;
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
  }
  
*{
    margin: 0%;
    padding: 0;
}
.body{
    margin: 2rem auto;
    /* transition: all .4s; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}
button:focus {outline:0;}
.alert-danger{
    color: #4d4d4d;
    background-color: #cacaca;
    border-color: #cecece;
}
.login_text{
    padding-top: 71px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 0%;
    text-align: center;
}
.campos{
    margin-top: 90px;
}
.input{
    margin-bottom: 30px;
    width: 500px;
    height: 40px;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    border: none;
    border-bottom: 1px solid #7A7A7A;
    background: transparent; 
    outline: none;
}
.uwu{
    display: flex;
    flex-direction: column;

}
.inputs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}
.boton{
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}
.aceptar{
    margin-top: 20px;
    cursor: pointer;
    width: 239px;
    height: 55px;
    border-radius: 50px;
    background-color: #1E1E1E;
    border: none;
    font-family: Montserrat !important;
    font-style: normal;
    color: #D3D3D3;
    font-weight: normal;
    font-size: 24px;
    line-height: 0%;
    transition: all .4s;
}
.dark-bg{
    background:  #272727;
}

.light-bg{
    background: #F8F8F8;
}
.aceptar:hover{
    transform: scale(1.1);
}
.registrar{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    margin-top: 30px;
}
.textoo{
    display: grid;
    text-align: center;
    grid-template-columns: .1fr .8fr .1fr ;
}

@media (max-width:600px){
    .input{
        width: 350px;
    }
    .aceptar{
        width: 200px;
        height: 60px;
        border-radius: 50px;
    }
    .login_text{
        font-size: 60px;
    }
    .registrar{
        font-size: 15px;
    }
}
.contenedor-general{
 text-align: center;
}
.drag-n-drop{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    color: #282828;
    margin-bottom: 40px;
}
.logo-upload{
    margin-top: 35px;
    margin-bottom: 35px;
}
.progress{
    margin: auto;
    width: 579px !important;
}
.contenedor{
    margin: auto auto;
    margin-top: 84px;
    text-align: center;
    width: 796px;
height: auto;
    background: #FFFEFE;
    border: 2px solid #FFFEFE;
    box-sizing: border-box;
    border-radius: 18px;
}

.vacio{
    margin: 20px auto;
}
.custom-file-upload {

    text-align: center;
    display: inline-block;
    padding: 23px 12px;
    cursor: pointer;
    width: 205px;
    height: 52px;
    margin-bottom: 18px;
    background: #DADADA;
    border-radius: 20px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    color: #272727;
}
.or-text{
    margin-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;

    color: #C9C9C9;
}
.boton-aceptar{
    width: 184px;
    margin-top: 30px;
    height: 66px;
    border: none;
    background: #DADADA;
    border-radius: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    color: #282828;
}
.img-zone{
    display: flex;
    justify-content: space-around;
    margin: 50px;
}
.cover-img{
    width: 170.1px;
    height: 163.15px;
    text-align: center;
    background: #F2F2F2;
    border-radius: 11px;
}
.image-preview{
    margin-top: 14px;
    width: 140.59px;
height: 112.82px;
object-fit: cover;
}
.browse-files-container{
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
*{
    scroll-behavior: smooth;
}
.total{
    display: flex;
    flex-direction: column;
}
.card{
    margin-top: 50px !important;
}
.card-body{
    height: auto;

    padding: 50px;
}
.pelele{
    text-align: center;
    justify-content: space-around;
    align-items: center;
    display: grid;
    grid-template-rows: .1fr .8fr .1fr;
}
.card-body p{
    margin-top:1rem !important;
}
.registro-txt{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-top: 50px;
    line-height: 0%;
    color: #282828;
}
.ultima-entrada-txt{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 50px;
    line-height: 0%;
    color: #615B5B;
}
.whole-body{
    /* margin-top: 140px; */
    display: grid;
    grid-template-columns: .2fr .8fr;
}
.container1{
    width: 883.08px;
/* height: 426.94px; */
}
.el-grafico-img{

    /* width: 100% !important; */
    width: 500px !important;
    height: 199.01px;
}
.c1{
    display: grid;
    grid-template-rows: .2fr .7fr .1fr;
}
/*perfil datos de la imagen*/
.profile-picture{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile-text{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 0%;color: #676767;
}
.profile-picture-img{
   width: 100px;;
  height: 100px;
  object-fit: cover;
    border-radius: 117px;
}

.change-profile-picture{
    background: #282828;
    border-radius: 50px;
    width: 84px;
    height: 24.56px;
    margin-top: 30px;
    font-family: 'Nunito', sans-serif;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 0%;
    color: #D3D3D3;
}
.qr-code-text{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 0%;
    color: #282828;

}
.qr-code-imag{
    width: 90px;
    height: 90px;
}
.profile-name{
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 25px;
    font-weight: 600;
}

/*datos del perfil*/
.datos-del-perfil{
    margin-left: 100px;
}

.datos-del-perfil-text{
    margin-bottom: 40px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 0%;
    color: #282828;
}
.fields{
    
    width: 400px;
}
.full-name-field{
    display: flex;
    margin-bottom: 18px;
}
.email-field{
    display: flex;
    margin-bottom: 18px;
}
.dni-field{
    display: flex;
    margin-bottom: 18px;
}
.companyid-field{
    
    display: flex;
}
.description{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 0%;
    color: #272727;
}
.hidden{
    display: none;
}
.value-field{
    margin-left: 10px;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 0%;
    width: 200px;
    color: #272727;
    height: 12px;
    border-bottom: 1px solid #7A7A7A;
}
@media (max-width:1400px){
.total{
    display: flex;
    flex-direction: column;
}
    /* .whole-body{
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    } */
    .profile-picture{
        margin-left: 0;

        margin-bottom: 40px;
    }
    .second-row{
        display: flex;
        /* flex-direction: column; */
        margin-top: 50px;
    }
    .second-row img{
        width: 300px;
        height: auto;
    }
    .qr-zone{
        border: none;
        margin-bottom: 60px;
    }
    .qr-img{
        width: 200px !important;
    }
    .qr-text,.chart-text{
        font-size: 30px;
    }
}

body{
    overflow-x: hidden;
}
.tabla-admin{
    font-family: 'Nunito', sans-serif;
}
.thead tr th{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #282828;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
td p a{
    color: #282828 !important;
    text-decoration: none;
}
.table thead th{
    border: none;
}
.profile-imgs{
    width: 71px;
    height: 57px;
    object-fit: cover;
    border-radius: 3px;
}
.container{
    max-width: 1400px;
    padding: 0 !important;
}
.botonera-admin{
    margin-top: 50px;
    margin-left: 30px;
    background-color: #E5E5E5;
    height: 60px;
    width: 264px;
    display :flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    border-radius: 30px;
}
#b1,#b2,#b3,#b4 {
    cursor: pointer;
}
.botonera{
    margin-bottom: 15px !important;
}

.boton-elim-border{
    border-left:  1px solid #D3D3D3;;
}
.contenedor-de-tabla{
    background: #FFFEFE;
    border-radius: 0px 0px 22px 22px;
}
.btn-elim{
    transition: all .1s;
}
.btn-elim:hover{
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}
.contenerdor-centro{
    width: 100%;
    display: flex;
    /* margin-top: 150px; */
    align-items: center;
    justify-content: space-around;
}
.thead-style{
    background-color: #FFFEFE !important;
}

.entradas-panel{
    overflow:auto;
    width: 60%;
    background-color: #d3d3d3;
    height: 400px;
    border-radius:10px;
}
.entradas-recientes{
    text-align: center;
}
.entrada:hover{
    transform: scale(1.02);
    cursor: pointer
}
.entrada{
    transition: all .3s;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    width: 70%;
    height: 100px;
    background-color: #FFFEFE ;
    margin: 9px auto;
    justify-content: center;
    border-radius: 10px;
}
.entrada p{
    margin: 0 !important;
}
.contenido-entrada{
    width: 500px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}
.contenido-entrada p{
    margin-left: 30px !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
}
.foto-contenido-entrada{
    cursor: pointer;
    width: 120px;
    height: 80px;
    object-fit: cover;
    border-radius: 15px;
}

.grafico-stats{
margin: auto auto;
    width: 1000px;
}
.contenedor-stats {
width: 100%;
}
.body-todo{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.parte-pagination{
    margin-top: 20px;
}
.b1{
    height: 100%;
}
.a1{
    height: 100%;
}
.loader-entradas{
    
}
.flecha-pagination{
    border-radius: 5px;
    background-color: #d3d3d3;
    color: #333;
    font-size: 20px;
    text-align: center;
    width: 50px;
    height: 30px;
    border: none;
    margin: auto 10px
}
.botones-paginas-tabla{
background: transparent;
border: none;
}
.boton-tabla{
    width: 230px;
height: 62px;
    display: flex !important;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    background-color:#E6E6E6 ;
    border-radius: 17px;
    transition: all .3s;
}
.boton-tabla:hover{
    background-color:#c0c0c0 ;
    
}
.boton-tabla p{
    margin: 0 !important;
    padding: 0 !important;

        
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #282828;
    margin-left:10px !important ;
}
.categoria-admin{
    display:flex;
    justify-content: center;
}
.modal ,.modal-header,.modal-footer{
    border:none !important;
}
.modal-body{
    padding:0 !important;
}
.modal{
border: 2px solid #555555;
box-sizing: border-box;
border-radius: 14px;
}
textarea:focus, input:focus, button:focus,select:focus{
    outline: none;
}
.unpit-dni-user{
    width: 246.96px;
    font-family: Montserrat;
    height: 46.81px;
    padding-left: 20px;
    background: #363636;
    border: 1px solid #555555;
    box-sizing: border-box;
    border-radius: 7px;
    margin-bottom :40px;
    color:white

}
.unpit-email-user{
    width: 350px;
    font-family: Montserrat;
    height: 46.81px;
    padding-left: 20px;
    background: #363636;
    border: 1px solid #555555;
    box-sizing: border-box;
    border-radius: 7px;
    color:white;
margin-top:0;
}
.btn-primary{
    border:none;
    background-color: rgba(85, 85, 85);
}
.btn-primary:hover{
    background-color: rgb(42, 42, 42);
}
.dni-text{
    
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #DADADA;
}
.opeciones {
    cursor: pointer;
    width: 181px;
    height: 202px;
    background: #1E1E1E;
    border-radius: 10px;
    transition: all .4s;
    margin: auto 40px
}
.option1 ,.option2{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.inside{
    margin: auto !important;

}
.modal-content{
    background-color: #272727;
}
.op-img{
    color: #DADADA;
    width:90px
}
.option2 img{
    height: 101px !important;
}
.op-p{
    margin: 0;
    margin-top:20px;
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 18px;

color: #DADADA !important;
}
